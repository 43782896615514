import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import Vue from 'vue'
import { SubsetCombineState, RootState } from '@/types/StoreTypes'

const state: SubsetCombineState = {
  step: 1,
  projectB: null,
  derivedProjectNameIsValid: false,
  derivedProjectName: null,
  samplesA: [],
  samplesB: [],
  mergeToggled: false
}

const getters: GetterTree<SubsetCombineState, RootState> = { }

const mutations: MutationTree<SubsetCombineState> = {
  setProjectB: (state, projectB) => {
    Vue.set(state, 'projectB', projectB)
  },
  setDerivedProjectNameIsValid: (state, isValid) => {
    Vue.set(state, 'derivedProjectNameIsValid', isValid)
  },
  setDerivedProjectName: (state, derivedName) => {
    Vue.set(state, 'derivedProjectName', derivedName)
  },
  setDerivedProject: (state, derivedProject) => {
    Vue.set(state, 'derivedProjectName', derivedProject.derivedName)
    Vue.set(state, 'derivedProjectNameIsValid', derivedProject.isValid)
  },
  setSamplesA: (state, selectedSamples) => {
    Vue.set(state, 'samplesA', selectedSamples)
  },
  setSamplesB: (state, selectedSamples) => {
    Vue.set(state, 'samplesB', selectedSamples)
  },
  setMergeToggled: (state, mergeToggled) => {
    Vue.set(state, 'mergeToggled', mergeToggled)
  },
  reset: (state) => {
    Vue.set(state, 'step', 1)
    Vue.set(state, 'derivedProjectName', null)
    Vue.set(state, 'derivedProjectNameIsValid', false)
    Vue.set(state, 'samplesA', [])
    Vue.set(state, 'samplesB', [])
    Vue.set(state, 'projectB', null)
    Vue.set(state, 'mergeToggled', false)
  }
}

const actions: ActionTree<SubsetCombineState, RootState> = {}

export const SubsetCombineStore: Module<SubsetCombineState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
