import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import { ILoggerOptions } from 'vuejs-logger/dist/interfaces/logger-options'
import { loggerPlugin, logOptions } from '@/plugins/logger'
import store from '@/store'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import vuetify from './plugins/vuetify'
import 'core-js/stable'
import i18n from '@/plugins/i18n'
// import "regenerator-runtime/runtime";

// const isProduction = process.env.NODE_ENV === 'production'

// const options = {
//   isEnabled: true,
//   logLevel: isProduction ? 'error' : 'debug',
//   stringifyArguments: false,
//   showLogLevel: true,
//   showMethodName: true,
//   separator: '|',
//   showConsoleColors: true
// }

Vue.use<ILoggerOptions>(loggerPlugin, logOptions)

Vue.config.productionTip = false

export default new Vue({
  router,
  i18n,
  vuetify,
  store,
  created () {
    this.$store.dispatch('createRoutes').catch((error) => {
      if (!error.status || error.status !== 401) {
        this.$log.debug('Could not load apps from server', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('pluginLoadError').toString()
        })
      }
    })
    // this.getData('http://localhost:8089/projects')
    // this.getDynamicRoutes('http://localhost:8089/projects')
    // this.createAndAppendRoute();
  },
  render: h => h(App)
}).$mount('#app')
