
import Component from 'vue-class-component'
import Vue from 'vue'

const SimpleConfirmDialogProps = Vue.extend({
  props: {
    persistent: { type: Boolean, required: false, default: false },
    showDialog: { type: Boolean, required: true },
    title: { type: String },
    type: { type: String },
    text: { type: String, required: true },
    isLoading: { type: Boolean, default: false },
    cancelColor: { type: String, default: 'admin' },
    confirmColor: { type: String, default: 'primary' },
    dark: { type: Boolean, default: false }
  }
})

@Component
export default class SimpleConfirmDialog extends SimpleConfirmDialogProps {
  get dialog () {
    return this.showDialog
  }

  set dialog (value) {
    this.$emit('update:showDialog', value)
  }

  closeDialog () {
    this.dialog = false
  }

  onConfirm () {
    this.$emit('update:isLoading', true)
    if (this.type) {
      this.$emit('onConfirm', this.type)
    } else {
      this.$emit('onConfirm')
    }
  }

  onCancel () {
    this.$emit('onCancel')
    this.closeDialog()
  }
}
