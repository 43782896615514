
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'
import { AuthService } from '@/services/AuthService'
import { NavBarType } from '@/types/Enums'

const UserProfileViewProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  }
})
export default class UserProfileView extends UserProfileViewProps {
  oldPassword = ''
  newPassword = ''
  repeatNewPassword = ''

  safePw = [
    (v: string) => !!v || this.$t('required'),
    (v: string) => /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(v) || this.$t('passwordWeak')
  ]

  passwordRepeatRules = [
    (v: string) => !!v || this.$t('required'),
    (v: string) => this.matchesPassword(v) || this.$t('passwordsNotIdentical')
  ]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  matchesPassword (password: string): boolean {
    return this.newPassword === password
  }

  submit () {
    if (this.form.validate()) {
      AuthService.updatePassword(this.oldPassword, this.newPassword).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('pwChangeSuccess').toString()
        })

        this.form.resetValidation()
        this.form.reset()
      }).catch((error) => {
        if (error.data.message === 'Wrong password') {
          this.$store.dispatch('notifications/showError', {
            text: this.$t('wrongPassword').toString()
          })

          this.form.resetValidation()
        } else {
          this.$log.debug('Could not change password: ', error)
          this.$store.dispatch('notifications/showError', {
            text: this.$t('passwordChangeError').toString()
          })
        }
      })
    }
  }

  mounted () {
    this.$store.commit('projects/setNavBarType', NavBarType.Default)
  }
}
