
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'

const LoginProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  },
  computed: {
    ...mapState([
      'loginFailed'
    ])
  }
})
export default class LoginView extends LoginProps {
  email = ''
  password = ''
  loading = false

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get isLogoutRedirect () {
    return this.$route.params.logout
  }

  backToLogin () {
    this.$router.push({ name: 'login' })
  }

  login () {
    this.form.validate()

    if (this.email && this.password) {
      const email = this.email
      const password = this.password
      this.$store.dispatch('authRequest', { email, password }).then(() => {
        this.forwardToDashboard()
      }).catch((error) => {
        if (error.status === 401) {
          this.$log.debug('Login Auth request failed')
          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.login.wrongCredentials').toString()
          })
        } else {
          this.$log.debug('Network issue', error)
          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.networkingError').toString()
          })
        }
      })
    }
  }

  forwardToDashboard () {
    this.$router.push({ name: 'dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }
}
