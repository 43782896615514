
import Component from 'vue-class-component'
import Vue from 'vue'
import { BaseApp } from '@/types/Types'
import { AdminAppList } from '@/types/Admin'
import { NavBarType } from '@/types/Enums'

const AdminAppsProps = Vue.extend({
  props: {
    //
  }
})

@Component
export default class AdminApps extends AdminAppsProps {
  adminAppList: Array<BaseApp> = AdminAppList

  changeRoute (routeName: string, params?: Record<string, string>): void {
    if (params && Object.keys(params).length) {
      this.$router.push({ name: routeName, params: params }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.$router.push({ name: routeName }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }

  mounted (): void {
    this.$store.commit('projects/setNavBarType', NavBarType.Admin)
  }
}
