import { AxiosPromise, ResponseType } from 'axios'
import { ApiService } from '@/api/ApiService'

export const DataStorageService = {
  uploadData (projectId: string, path: string, data: File): AxiosPromise {
    const formData = new FormData()
    formData.set('data', data)
    formData.set('path', path)

    return ApiService({
      url: '/projects/data/upload/' + projectId,
      method: 'post',
      data: formData
    })
  },

  downloadData (projectId: string, path: string, responseType: ResponseType = 'blob'): AxiosPromise {
    return ApiService({
      url: '/projects/data/' + projectId + '/' + path,
      method: 'get',
      responseType: responseType
    })
  },

  generatePreSignedURL (bucketId: string, objectName: string) {
    const formData = new FormData()
    formData.set('bucketId', bucketId)
    formData.set('objectName', objectName)

    return ApiService({
      url: '/projects/data/presignedURL',
      method: 'post',
      data: formData
    })
  },

  deleteData (projectId: string, path: string): AxiosPromise {
    return ApiService({
      url: '/projects/data/' + projectId + '/' + path,
      method: 'delete'
    })
  },

  getBucketList (projectId: string): AxiosPromise {
    return ApiService({
      url: '/projects/list/' + projectId,
      method: 'get'
    })
  },

  getBucketFolder (projectId: string, folder: string): AxiosPromise {
    return ApiService({
      url: '/projects/list/' + projectId + '/' + folder,
      method: 'get'
    })
  },

  regenerateSecret (projectId: string): AxiosPromise {
    return ApiService({
      // For now we assume that there is only one user in existence for each bucket
      // The backend can actually handle more, but this would need some frontend restructuring
      url: '/project/regenerateSecret/' + projectId + '/' + projectId + '-1',
      method: 'get'
    })
  }
}
