import { Project } from '@/types/Types'

export const ProjectUtils = {
  getLogoName (project: Project | null):string {
    if (!project || !project.projectType) {
      return 'computomics-logo.svg'
    }

    switch (project.projectType?.name) {
      case 'Metagenomics WGS':
        return 'morpheus_logo_no_claim.svg'
      case 'Metagenomics 16S':
        return 'morpheus_logo_no_claim.svg'
      case 'xSeedScore':
        return 'xseedscore_logo_no_claim.svg'
      case 'Data Transfer':
        return 'data-transfer-logo-noclaim.svg'
      default:
        return 'computomics_logo_no_claim.svg'
    }
  },

  getLogoNameByProjectTypeName (projectTypeName: string):string {
    switch (projectTypeName) {
      case 'Metagenomics WGS':
        return 'morpheus_logo_no_claim.svg'
      case 'Metagenomics 16S':
        return 'morpheus_logo_no_claim.svg'
      case 'xSeedScore':
        return 'xseedscore_logo_no_claim.svg'
      case 'Data Transfer':
        return 'data-transfer-logo-noclaim.svg'
      default:
        return 'computomics_logo_no_claim.svg'
    }
  }
}
