

import Component from 'vue-class-component'
import Vue from 'vue'
import { DateTime } from 'luxon'

const GlobalFooterProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  }
})
export default class GlobalFooter extends GlobalFooterProps {
  get isAuth () {
    return this.$store.getters.isAuthenticated
  }

  get currentYear () {
    return DateTime.now().year
  }
}
