
import Component from 'vue-class-component'
import Vue from 'vue'
import router from '@/plugins/router'

const GlobalHeaderProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  }
})
export default class GlobalHeader extends GlobalHeaderProps {
  loggedInMenuOpen = false

  get showToolbarIcon () {
    return this.$store.getters.toolbarVisible
  }

  get isAdmin () {
    return this.$store.state.auth.isAdmin
  }

  get isBasicUser () {
    return this.$store.getters.isBasicUser
  }

  sendHome () {
    if (this.$router.currentRoute.name !== 'dashboard') {
      this.$router.push({ name: 'dashboard' }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }

  changeRoute (routeName: string): void {
    this.$router.push({ name: routeName }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  logout () {
    this.$store.dispatch('authLogout').then(() => {
      router.push({ name: 'login', params: { logout: 'true' } })
    }).catch(() => {
      this.$log.debug('logout dispatch error')
    })
  }

  get platformHelpUrl () {
    return process.env.VUE_APP_HELP_URL
  }

  gotoProfile () {
    this.$router.push('/profile').catch()
  }

  goToHelp () {
    window.open(this.platformHelpUrl, '_blank')
  }
}
