
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class AdminSettings extends Vue {
  mounted () {
    // Fetches all project types
    this.$store.dispatch('projects/getProjectTypes')
  }
}
