
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue'
import * as jsonpatch from 'fast-json-patch'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import { VForm } from '@/$refs'
import { Role, SortParams, User } from '@/types/Types'
import { Observer } from 'fast-json-patch/commonjs/duplex'
import { Operation } from 'fast-json-patch/module/core'
import { UserService } from '@/services/UserService'
import { RoleService } from '@/services/RoleService'
import { mail, matchPassword, required, safePw } from '@/utils/InputRules'

const UserTableProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    SimpleConfirmDialog
  }
})
export default class UserTable extends UserTableProps {
  users: Array<User> = []
  roles: Array<Role> = []
  search = ''
  loading = true
  createUserDialog = false
  editUser = false
  userIdMarkedForChange: string | null = null
  showDeleteDialog = false
  selectedUser: User = {
    email: ''
  }

  selectedUserObserver: Observer<User> | null = null
  password1 = ''
  password2 = ''
  emailRule = [required(), mail()]
  passwordRule = [required(), safePw()]
  passwordRepeatRules = [required(), matchPassword(this.matchesPassword)]

  roleRequired = [
    (v: string) => !!v || this.$t('required')
  ]

  options = {
    sortBy: ['email'],
    sortDesc: [false],
    page: 1,
    itemsPerPage: -1
  }

  items = []
  headers = [
    { text: this.$t('adminSettings.userManagement.userTable.header.email'), value: 'email', align: 'left' },
    { text: this.$t('adminSettings.userManagement.userTable.header.roles'), value: 'roles', align: 'left' },
    { text: this.$t('adminSettings.userManagement.userTable.header.actions'), value: 'action', sortable: false, align: 'right' }
  ]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get dialogHeader () {
    if (this.editUser) {
      return this.$t('editUser')
    } else {
      return this.$t('createUser')
    }
  }

  get isEditPassword () {
    return !this.editUser || (!!this.password1 || !!this.password2)
  }

  @Watch('options', { deep: true })
  onOptionsChanged (): void {
    this.getUsers()
  }

  removeSelectedRole (role: Role) {
    const index = this.selectedUser?.roles?.map(item => item.name).indexOf(role.name)

    if (index !== undefined && index >= 0) {
      this.selectedUser?.roles?.splice(index, 1)
    }
  }

  matchesPassword (password: string): boolean {
    return this.password1 === password
  }

  resetSelectedUser () {
    this.selectedUserObserver = null

    this.selectedUser = {
      email: ''
    }

    this.password1 = ''
    this.password2 = ''
  }

  getRoles () {
    RoleService.getAllRoles().then((response) => {
      this.roles = response.data
    }).catch((error) => {
      this.$log.debug('Error loading roles from server: ', error)
      this.$store.dispatch('notifications/showError', {
        text: this.$t('notifications.userTable.roleLoadError').toString()
      })
    })
  }

  getUsers () {
    let sortOrder = 'desc'
    this.loading = true

    const { sortBy, sortDesc } = this.options

    if (sortDesc[0]) {
      sortOrder = 'desc'
    } else {
      sortOrder = 'asc'
    }

    const params: SortParams = {
      sort: sortBy + ',' + sortOrder
    }

    UserService.getAllUsers(params).then((response) => {
      this.users = response.data
    }).catch((error) => {
      this.$log.debug('Error loading users from server: ', error)
      this.$store.dispatch('notifications/showError', {
        text: this.$t('notifications.userTable.userLoadError').toString()
      })
    }).finally(() => {
      this.loading = false
    })
  }

  confirmDeleteUser (id: string) {
    this.userIdMarkedForChange = id

    this.showDeleteDialog = true
  }

  closeDeleteDialog () {
    this.userIdMarkedForChange = null

    this.showDeleteDialog = false
  }

  openEditUserDialog (user: User) {
    if (user.id) {
      // copy the object to the selected user
      this.selectedUser = JSON.parse(JSON.stringify(user))
      this.password1 = ''
      this.password2 = ''
      // add an observer for the patch operation
      this.selectedUserObserver = jsonpatch.observe(this.selectedUser)

      this.editUser = true
      this.userIdMarkedForChange = user.id
      this.createUserDialog = true
    }
  }

  openNewUserDialog () {
    this.editUser = false
    this.createUserDialog = true
  }

  resetUserDialog () {
    this.form.resetValidation()
    this.resetSelectedUser()

    this.createUserDialog = false
    this.userIdMarkedForChange = null
  }

  deleteUser () {
    this.loading = true

    if (this.userIdMarkedForChange) {
      UserService.deleteUser(this.userIdMarkedForChange).then(() => {
        this.getUsers()
        this.userIdMarkedForChange = null

        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('userDeletedSuccess').toString()
        })
      }).catch((error) => {
        this.$log.debug('Error deleting user from server: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))

        this.$store.dispatch('notifications/showError', {
          text: this.$t('userDeletedFailed')
        })
      }).finally(() => {
        this.loading = false
        this.showDeleteDialog = false
      })
    }
  }

  saveUser () {
    if (this.editUser) {
      this.updateUser()
    } else {
      this.createUser()
    }
  }

  updateUser () {
    let patch: Operation[] = []

    if (this.form.validate()) {
      // TODO: Rework this!
      let passwordChange: Operation | null = null

      if (this.password1) {
        if (!this.form.validate()) {
          return
        } else {
          // Only supply the password if actually set
          passwordChange = {
            op: 'replace',
            path: '/password',
            value: this.password1
          }
        }
      }

      // This generates a json patch object
      if (this.selectedUserObserver) {
        patch = jsonpatch.generate(this.selectedUserObserver)
      }
      // However, we need to manually adjust the roles and update the password if needed
      if (passwordChange != null) {
        patch.push(passwordChange)
      }

      if (patch.length > 0 && this.userIdMarkedForChange) {
        this.loading = true

        UserService.patchUser(this.userIdMarkedForChange, patch).then(() => {
          this.getUsers()

          this.$store.dispatch('notifications/showSuccess', {
            text: this.$t('userUpdateSuccess').toString()
          })
        }).catch((error) => {
          this.$log.debug('Error updating user on server: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))
          this.$store.dispatch('notifications/showError', {
            text: this.$t('userUpdateFailed')
          })
        }).finally(() => {
          this.loading = false
          this.resetUserDialog()
        })
      } else {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('userUpdateNotNeeded').toString()
        })
        this.resetUserDialog()
      }
    }
  }

  createUser () {
    if (this.form.validate()) {
      this.loading = true

      const userData = {
        email: this.selectedUser.email,
        password: this.password1,
        roles: this.selectedUser.roles,
        enabled: true
      }

      UserService.registerUser(userData).then(() => {
        this.getUsers()

        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('userCreatedSuccess').toString()
        })
      }).catch((error) => {
        this.$log.debug('Error creating user on server: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))

        this.$store.dispatch('notifications/showError', {
          text: this.$t('userCreatedFailed')
        })
      }).finally(() => {
        this.loading = false
        this.resetUserDialog()
      })
    }
  }

  mounted () {
    this.getRoles()
  }
}
