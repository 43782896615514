import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { NotificationState, RootState } from '@/types/StoreTypes'

const namespaced = true

const state: NotificationState = {
  text: '',
  open: false,
  timeout: 5000,
  color: 'error',
  hideNews: false
}

const mutations: MutationTree<NotificationState> = {
  setText (state, payload) {
    state.text = payload
  },

  setOpenState (state, payload) {
    state.open = payload
  },

  setTimeout (state, payload) {
    state.timeout = payload
  },

  setColor (state, payload) {
    state.color = payload
  },

  setHideNews (state, payload) {
    state.hideNews = payload
  }
}

const actions: ActionTree<NotificationState, RootState> = {
  showNotification ({ commit }, data: any) {
    // We need at least some text to display any message
    if (data.text) {
      commit('setText', data.text)

      // Set additional options, if given
      if (data.color) {
        commit('setColor', data.color)
      }

      if (typeof data.timeout !== 'undefined' && data.timeout !== null) {
        commit('setTimeout', data.timeout)
      }

      commit('setOpenState', true)
    }
  },

  showWarning ({ dispatch }, data: any) {
    if (data.text) {
      data.color = 'warning'
      dispatch('showNotification', data)
    }
  },

  showError ({ dispatch }, data: any) {
    if (data.text) {
      data.color = 'error'
      dispatch('showNotification', data)
    }
  },

  showSuccess ({ dispatch }, data: any) {
    if (data.text) {
      data.color = 'green'
      dispatch('showNotification', data)
    }
  },

  hideNotification ({ commit }) {
    commit('setOpenState', false)
  }
}

const getters: GetterTree<NotificationState, RootState> = {
}

export const NotificationStore: Module<NotificationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
