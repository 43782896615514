
import Vue from 'vue'
import Component from 'vue-class-component'
import ProjectNavigationBox from '@/components/ProjectNavigationBox.vue'
import { Project } from '@/types/Types'
import { NavBarType } from '@/types/Enums'
import { ProjectService } from '@/services/ProjectService'
import LoadingCard from '@/components/LoadingCard.vue'
import ProjectList from '@/components/ProjectList.vue'

const ProjectsOverviewProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    ProjectList,
    LoadingCard,
    ProjectNavigationBox
  }
})
export default class ProjectsOverview extends ProjectsOverviewProps {
  get includePublic (): boolean {
    return this.$store.state.projects.includePublic
  }

  set includePublic (value: boolean) {
    this.$store.commit('projects/setIncludePublic', value)
  }

  get myProjects (): Array<Project> {
    return this.$store.state.projects.myProjects
  }

  get publicProjects (): Array<Project> {
    return this.$store.state.projects.publicProjects
  }

  get projectsLoading (): boolean {
    return this.$store.state.projects.projectsLoading
  }

  get myProjectsLoading (): boolean {
    return this.$store.state.projects.myProjectsLoading
  }

  get publicProjectsLoading (): boolean {
    return this.$store.state.projects.publicProjectsLoading
  }

  get isAdmin () {
    return this.$store.state.auth.isAdmin
  }

  get isBasicUser () {
    return this.$store.getters.isBasicUser
  }

  get projects () {
    if (this.includePublic && !this.isBasicUser) {
      return [...this.myProjects, ...this.publicProjects]
    } else {
      return this.myProjects
    }
  }

  get hasPublicProjects () {
    return !!this.publicProjects && this.publicProjects.length > 0
  }

  changeRoute (routeName: string, params?: Record<string, string>): void {
    if (params && Object.keys(params).length) {
      this.$router.push({ name: routeName, params: params }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.$router.push({ name: routeName }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }

  mounted (): void {
    this.$store.commit('projects/setSelectedProjectId', null)
    this.$store.commit('projects/setNavBarType', NavBarType.Default)
  }
}
