import { ProjectState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import Vue from 'vue'
import { NavBarType, ProjectGetType } from '@/types/Enums'
import { ProjectService } from '@/services/ProjectService'
import { ProjectTypeService } from '@/services/ProjectTypeService'
import { AxiosResponse } from 'axios'
import { SortParams } from '@/types/Types'
import store from '@/store'

const state: ProjectState = {
  projects: [],
  projectsLoading: true,
  myProjects: [],
  myProjectsLoading: true,
  publicProjects: [],
  publicProjectsLoading: true,
  projectTypes: null,
  navBarType: NavBarType.Default,
  selectedProjectId: null,
  selectedProject: null,
  selectedProjectType: null,
  includePublic: true
}

const mutations: MutationTree<ProjectState> = {
  setProjects: (state, value) => {
    Vue.set(state, 'projects', value)
  },

  setMyProjects: (state, value) => {
    Vue.set(state, 'myProjects', value)
  },

  setPublicProjects: (state, value) => {
    Vue.set(state, 'publicProjects', value)
  },

  setProjectsLoading: (state, value) => {
    Vue.set(state, 'projectsLoading', value)
  },

  setMyProjectsLoading: (state, value) => {
    Vue.set(state, 'myProjectsLoading', value)
  },

  setPublicProjectsLoading: (state, value) => {
    Vue.set(state, 'publicProjectsLoading', value)
  },

  setProjectTypes: (state, value) => {
    Vue.set(state, 'projectTypes', value)
  },

  setNavBarType: (state, value) => {
    Vue.set(state, 'navBarType', value)
  },

  setSelectedProjectId: (state, value) => {
    Vue.set(state, 'selectedProjectId', value)
  },

  setSelectedProject: (state, value) => {
    Vue.set(state, 'selectedProject', value)
  },

  setSelectedProjectType: (state, value) => {
    Vue.set(state, 'selectedProjectType', value)
  },

  setIncludePublic: (state, value) => {
    Vue.set(state, 'includePublic', value)
  }
}

const actions: ActionTree<ProjectState, RootState> = {
  refreshProjects ({ dispatch }) {
    return new Promise<void>((resolve, reject) => {
      const promises: any = []

      promises.push(dispatch('getProjects', { type: ProjectGetType.MINE }))
      promises.push(dispatch('getProjects', { type: ProjectGetType.EXAMPLE_FILTERED }))

      Promise.allSettled(promises).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },

  getProjects ({ commit }, payload) {
    // case insensitive sorting: { sort: 'name,asc,ignoreCase' } not yet supported by mongodb.
    // if needed sort in frontend...
    const params: SortParams = {
      sort: 'name,asc'
    }

    return new Promise<void>((resolve, reject) => {
      // set loading
      let type = ''
      if (payload.type === 'all') {
        commit('setProjectsLoading', true)
        type = payload.type
      } else if (payload.type === 'mine') {
        commit('setMyProjectsLoading', true)
        type = payload.type
      } else if (payload.type === 'public') {
        commit('setPublicProjectsLoading', true)
        type = payload.type
      } else if (payload.type === 'filtered_public') {
        commit('setPublicProjectsLoading', true)
        // remove "false" with filter to get actual length
        if (store.state.auth.userSettings && store.state.auth.userSettings.showPublicProjectsForType &&
          Object.values(store.state.auth.userSettings.showPublicProjectsForType).filter(Boolean).length > 0) {
          const publicProjectTypeIds: Array<string> = Object.entries(store.state.auth.userSettings.showPublicProjectsForType)
            .filter(item => item[1])
            .map(item => item[0])
          type = publicProjectTypeIds.join(',')
        } else {
          // if there are no types set to public simply return.
          commit('setPublicProjectsLoading', false)
          commit('setPublicProjects', [])
          resolve()
          // return after resolve to skip the rest of the function, in case this is not the end of a function!
          return
        }
      }

      ProjectTypeService.getProjectByType(type, params).then((response) => {
        if (payload.type === 'all') {
          commit('setProjects', response.data)
        } else if (payload.type === 'mine') {
          commit('setMyProjects', response.data)
        } else if (payload.type === 'public' || payload.type === 'filtered_public') {
          commit('setPublicProjects', response.data)
        }

        resolve()
      }).catch((error) => {
        commit('setProjects', [])
        reject(error)
      }).finally(() => {
        if (payload.type === 'all') {
          commit('setProjectsLoading', false)
        } else if (payload.type === 'mine') {
          commit('setMyProjectsLoading', false)
        } else if (payload.type === 'public' || payload.type === 'filtered_public') {
          commit('setPublicProjectsLoading', false)
        }
      })
    })
  },

  getProjectTypes ({ commit, state }, payload) {
    return new Promise<void>((resolve, reject) => {
      ProjectTypeService.getProjectTypes().then((response: AxiosResponse) => {
        commit('setProjectTypes', response.data)

        resolve()
      }).catch((error) => {
        commit('setProjectTypes', [])
        reject(error)
      })
    })
  },

  getSelectedProjectInfo ({ commit, state }) {
    return new Promise<void>((resolve, reject) => {
      if (!state.selectedProjectId) {
        reject(new Error('no project id set'))
      } else {
        ProjectService.getProjectById(state.selectedProjectId).then((result) => {
          commit('setSelectedProject', result.data)

          if (state.selectedProject?.projectType?.id) {
            ProjectTypeService.getProjectTypeById(state.selectedProject.projectType.id).then((result) => {
              commit('setSelectedProjectType', result.data)

              resolve()
            }).catch((error) => {
              reject(error)
            })
          } else {
            reject(new Error('no id given'))
          }
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }
}

const getters: GetterTree<ProjectState, RootState> = {
}

export const ProjectStore: Module<ProjectState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
