
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class GlobalNotificationBar extends Vue {
  get color (): string {
    return this.$store.state.notifications.color
  }

  set color (value: string) {
    this.$store.commit('notifications/setColor', value)
  }

  get open (): boolean {
    return this.$store.state.notifications.open
  }

  set open (value: boolean) {
    this.$store.commit('notifications/setOpenState', value)
  }

  get timeout (): number {
    return this.$store.state.notifications.timeout
  }

  set timeout (value: number) {
    this.$store.commit('notifications/setTimeout', value)
  }

  get text (): string {
    return this.$store.state.notifications.text
  }

  set text (value: string) {
    this.$store.commit('notifications/setText', value)
  }
}
