import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { PipelineRun } from '@/types/Types'

export const PipelineService = {

  getAllPipelines (): AxiosPromise {
    return ApiService({
      url: '/pipelines/all'
    })
  },

  getPipelineById (pipelineId: string): AxiosPromise {
    return ApiService({
      url: '/pipeline/' + pipelineId,
      method: 'get'
    })
  },

  getPipelinesForApp (appId: string): AxiosPromise {
    return ApiService({
      url: '/pipelines/app/' + appId,
      method: 'get'
    })
  },

  getRunsForCurrentUserGroup (): AxiosPromise {
    return ApiService({
      url: '/runs',
      method: 'get'
    })
  },

  getAllRuns (): AxiosPromise {
    return ApiService({
      url: '/runs/all',
      method: 'get'
    })
  },

  getRunById (runId: string): AxiosPromise {
    return ApiService({
      url: '/run/' + runId,
      method: 'get'
    })
  },

  deleteRunById (runId: string): AxiosPromise {
    return ApiService({
      url: '/runs/' + runId,
      method: 'delete'
    })
  },

  startPipelineRun (pipelineRun: PipelineRun): AxiosPromise {
    return ApiService({
      url: '/run-pipeline',
      method: 'post',
      data: pipelineRun
    })
  }
}
