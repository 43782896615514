
import Vue from 'vue'
import Component from 'vue-class-component'
import { Group, Project } from '@/types/Types'
import { ProjectService } from '@/services/ProjectService'
import { ProjectUtils } from '@/utils/ProjectUtils'

const ProjectListProps = Vue.extend({
  props: {
    projects: { type: Array as () => Array<Project>, required: true }
  }
})

@Component
export default class ProjectList extends ProjectListProps {
  currentEditId = ''
  currentEditText = ''

  get isAdmin () {
    return this.$store.state.auth.isAdmin
  }

  get userGroups (): Array<Group> {
    return this.$store.state.auth.userGroups
  }

  getLogoName (project: Project):string {
    return ProjectUtils.getLogoName(project)
  }

  canEdit (project: Project) {
    return (!project.publicVisible && this.userGroups.map(group => group.id).includes(project.ownerGroup.id)) || this.isAdmin
  }

  hasAnalysis (project: Project) {
    return project.projectType?.amountOfAnalysisApps && project.projectType?.amountOfAnalysisApps > 0
  }

  editProjectDescription (project: Project) {
    if (project.id) {
      this.currentEditId = project.id
      if (project.description) {
        this.currentEditText = project.description
      } else {
        this.currentEditText = ''
      }
    }
  }

  saveEdit (project: Project) {
    ProjectService.updateDescription(this.currentEditId, this.currentEditText, !project.description).then(() => {
      // update text manually, so we do not have to reload the entire project
      project.description = this.currentEditText

      this.currentEditId = ''
      this.currentEditText = ''
    }).catch((error) => {
      this.$log.debug('Could not update project: ', error)
      this.$store.dispatch('notifications/showError', {
        text: this.$t('notifications.projectOverview.descriptionUpdateError').toString()
      })
    })
  }

  cancelEdit () {
    this.currentEditId = ''
    this.currentEditText = ''
  }

  changeRoute (routeName: string, params?: Record<string, string>): void {
    if (params && Object.keys(params).length) {
      this.$router.push({ name: routeName, params: params }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.$router.push({ name: routeName }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }
}
