
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue'
import * as jsonpatch from 'fast-json-patch'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import { VForm } from '@/$refs'
import { Group, SortParams, TableGroupItem, User } from '@/types/Types'
import { Observer } from 'fast-json-patch/module/duplex'
import { Operation } from 'fast-json-patch/module/core'
import { GroupService } from '@/services/GroupService'
import { UserService } from '@/services/UserService'
import { ProjectGetType } from '@/types/Enums'

const GroupTableProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    SimpleConfirmDialog
  }
})
export default class GroupTable extends GroupTableProps {
  EMPTY_GROUP = {
    name: '',
    projectQuota: 0
  }

  maxUserDisplay = 6
  groups: Array<Group> = []
  users: Array<User> = []
  selectedUsers: Array<User> = []
  createGroupDialog = false
  groupIdMarkedForChange: string | null = null
  selectedGroupObserver: Observer<Group> | null = null
  editGroup = false
  selectedGroup: Group = JSON.parse(JSON.stringify(this.EMPTY_GROUP))
  showDialog = false
  search = ''

  stringRequired = [
    (v: string) => !!v || this.$t('required')
  ]

  numberRequired = [
    (v: number) => v >= 0 || this.$t('required')
  ]

  loading = true
  options = {
    sortBy: ['name'],
    sortDesc: [false],
    page: 1,
    itemsPerPage: -1
  }

  items = []
  headers = [
    {
      text: this.$t('adminSettings.groupManagement.groupTable.header.groupName'),
      value: 'name',
      align: 'left'
    },
    {
      text: this.$t('adminSettings.groupManagement.groupTable.header.groupMembers'),
      value: 'users',
      sortable: false,
      align: 'left'
    },
    {
      text: this.$t('adminSettings.groupManagement.groupTable.header.actions'),
      value: 'action',
      sortable: false,
      align: 'right'
    }
  ]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get dialogHeader () {
    if (this.editGroup) {
      return this.$t('adminSettings.groupManagement.groupTable.editGroup')
    } else {
      return this.$t('adminSettings.groupManagement.groupTable.createGroup')
    }
  }

  get tableItems (): Array<TableGroupItem> {
    return this.groups.map(group => ({
      ...group,
      projects: []
    }))
  }

  @Watch('options')
  onOptionsChanged (): void {
    this.getGroups()
  }

  getGroupEmails (users: Array<User>): string {
    if (users.length > this.maxUserDisplay) {
      return users.slice(0, this.maxUserDisplay).map(item => item?.email || '').join(', ')
    } else {
      return users.map(item => item?.email || '').join(', ')
    }
  }

  getGroups () {
    let sortOrder = 'asc'
    this.loading = true

    const {
      sortBy,
      sortDesc
    } = this.options

    if (sortDesc[0]) {
      sortOrder = 'desc'
    } else {
      sortOrder = 'asc'
    }

    const params: SortParams = {
      sort: sortBy + ',' + sortOrder
    }

    GroupService.getAllGroups(params).then((response) => {
      this.groups = response.data
    }).catch((error) => {
      this.$log.debug('Error loading groups from server: ', error)
      this.$store.dispatch('notifications/showError', {
        text: this.$t('groupLoadError').toString()
      })
    }).finally(() => {
      this.loading = false
    })
  }

  getAllEmails () {
    this.loading = true

    const params: SortParams = {
      sort: 'email,asc'
    }

    UserService.getAllUserEmails(params).then((response) => {
      this.users = response.data
    }).catch((error) => {
      this.$log.debug('Error loading users from server: ', error)
      this.$store.dispatch('notifications/showError', {
        text: this.$t('userLoadError').toString()
      })
    }).finally(() => {
      this.loading = false
    })
  }

  removeSelectedUser (user: User) {
    const index = this.selectedUsers.map(item => item.id).indexOf(user.id)
    if (index >= 0) {
      this.selectedUsers.splice(index, 1)
    }
  }

  openEditGroupDialog (group: Group) {
    // copy the object to the selected user
    this.selectedGroup = JSON.parse(JSON.stringify(group))
    // add an observer for the patch operation
    if (this.selectedGroup && group.id) {
      this.selectedGroupObserver = jsonpatch.observe(this.selectedGroup)

      if (group.users) {
        this.selectedUsers = group.users
      } else {
        this.selectedUsers = []
      }

      this.editGroup = true
      this.groupIdMarkedForChange = group.id
      this.createGroupDialog = true
    }
  }

  openNewGroupDialog () {
    this.editGroup = false
    this.createGroupDialog = true
  }

  resetGroupDialog () {
    this.form.resetValidation()
    this.resetSelectedGroup()

    this.createGroupDialog = false
    this.groupIdMarkedForChange = null
  }

  resetSelectedGroup () {
    this.selectedGroupObserver = null
    this.selectedGroup = JSON.parse(JSON.stringify(this.EMPTY_GROUP))
  }

  confirmDeleteGroup (id: string) {
    this.groupIdMarkedForChange = id
    this.showDialog = true
  }

  cancelDeleteGroup () {
    this.groupIdMarkedForChange = null
    this.showDialog = false
  }

  saveGroup () {
    if (this.editGroup) {
      this.updateGroup()
    } else {
      this.createGroup()
    }
  }

  updateGroup () {
    // Do not update the users of a group using generate patch...
    // this may generate very big patch files
    // Use map arrays of objects to ids and compare those.
    // this.selectedGroup.users = this.selectedUsers;
    let oldUserIds = []
    let newUserIds = []
    let patch: Operation[] | null = []

    if (this.form.validate() && this.selectedGroup) {
      if (!!this.selectedGroup.users && this.selectedGroup.users.length > 0) {
        oldUserIds = this.selectedGroup.users.filter((x: any) => !!x).map((user: any) => user.id)
      }

      if (!!this.selectedUsers && this.selectedUsers.length > 0) {
        newUserIds = this.selectedUsers.filter((x: any) => !!x).map((user: any) => user.id)
      }

      if (this.selectedGroupObserver) {
        patch = jsonpatch.generate(this.selectedGroupObserver)
      }

      if (JSON.stringify(oldUserIds) !== JSON.stringify(newUserIds)) {
        // rekey ids
        newUserIds = newUserIds.map((userId: string) => {
          return { id: userId }
        })
        patch.push({
          op: 'replace',
          path: '/users',
          value: newUserIds
        })
      }

      if (patch.length > 0 && this.groupIdMarkedForChange) {
        this.loading = true

        GroupService.updateGroup(this.groupIdMarkedForChange, patch).then(() => {
          this.getGroups()
          this.$store.dispatch('notifications/showSuccess', {
            text: this.$t('groupUpdateSuccess').toString()
          })

          // Refresh projects in Navbox
          this.refreshProjects()
        }).catch((error) => {
          this.$log.debug('Error updating group on server: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))
          this.$store.dispatch('notifications/showError', {
            text: this.$t('groupUpdateFailed')
          })
        }).finally(() => {
          this.loading = false
          this.resetGroupDialog()
        })
      } else {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('groupUpdateNotNeeded').toString()
        })

        this.resetGroupDialog()
      }
    }
  }

  createGroup () {
    if (this.form.validate() && this.selectedGroup) {
      this.loading = true

      const groupData = {
        name: this.selectedGroup.name,
        users: this.selectedUsers,
        projectQuota: this.selectedGroup.projectQuota
      }

      GroupService.createGroup(groupData).then(() => {
        this.getGroups()
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('groupCreatedSuccess').toString()
        })
      }).catch((error) => {
        this.$log.debug('Error creating group on server: ', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('groupCreatedFailed')
        })
      }).finally(() => {
        this.loading = false
        this.resetGroupDialog()
      })
    }
  }

  deleteGroup () {
    this.loading = true

    if (this.groupIdMarkedForChange) {
      GroupService.deleteGroup(this.groupIdMarkedForChange).then(() => {
        this.getGroups()
        this.groupIdMarkedForChange = null
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('groupDeletedSuccess').toString()
        })
      }).catch((error) => {
        this.$log.debug('Error deleting group from server: ', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('groupDeletedFailed')
        })
      }).finally(() => {
        this.loading = false
        this.showDialog = false
      })
    }
  }

  refreshProjects () {
    this.$store.dispatch('projects/refreshProjects')
  }

  mounted () {
    this.getAllEmails()
  }
}
