export enum PlotType {
  Box = 'box',
  Heat = 'heat',
  KeggHeat = 'keggHeat',
  KeggStacked = 'keggStacked',
  Meta = 'meta',
  Scatter = 'scatter',
  Stacked = 'stacked'
}

export enum NavBarType {
  Default = 'default',
  Data = 'data',
  Analysis = 'analysis',
  Admin = 'admin'
}

export enum PipelineRunStatus {
  READY = 'READY',
  INITIALIZING = 'INITIALIZING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export enum ProjectGetType {
  ALL = 'all',
  MINE = 'mine',
  // avoid naming this public as this is a JS keyword
  EXAMPLE = 'public',
  EXAMPLE_FILTERED = 'filtered_public'
}
