import { AuthState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { ApiService } from '@/api/ApiService'
import store from '@/store'
import vuem from '@/main'
import router from '@/plugins/router'
import { AuthService } from '@/services/AuthService'
import { GroupService } from '@/services/GroupService'
import { User } from '@/types/Types'
import { AxiosResponse } from 'axios'
import { UserService } from '@/services/UserService'
import { AppService } from '@/services/AppService'

const state: AuthState = {
  token: '',
  status: '',
  isAdmin: false,
  userGroups: [],
  userRoles: [],
  userSettings: null
}

const mutations: MutationTree<AuthState> = {
  authRequest (state) {
    state.status = 'loading'
  },
  setToken (state, token) {
    state.token = token
  },
  setIsAdmin (state, value) {
    state.isAdmin = value
  },
  setUserGroups (state, value) {
    state.userGroups = value
  },
  setUserRoles (state, value) {
    state.userRoles = value
  },
  setUserSettings (state, value) {
    state.userSettings = value
  }
}

const actions: ActionTree<AuthState, RootState> = {
  authRequest ({ commit, dispatch }, user) {
    return new Promise<void>((resolve, reject) => { // The Promise used for router redirect in login
      commit('authRequest')

      AuthService.login(user).then((response) => {
        const token = response.data.token
        commit('setToken', token)

        const promises: any = []

        promises.push(dispatch('createRoutes'))
        promises.push(dispatch('pullUserSettings'))
        promises.push(dispatch('pullUser'))
        promises.push(dispatch('pullUserGroups'))

        Promise.allSettled(promises).then(() => {
          dispatch('projects/refreshProjects')
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }).catch(err => {
        dispatch('authLogout')
        reject(err)
      })
    })
  },

  clearLoginData ({ commit }) {
    return new Promise<void>((resolve) => {
      commit('setToken', '')
      commit('setIsAdmin', false)
      commit('setUserSettings', null)
      commit('setUserRoles', [])
      commit('setUserGroups', [])

      resolve()
    })
  },

  authLogout ({ dispatch }) {
    return new Promise<void>((resolve) => {
      dispatch('clearLoginData')

      resolve()
    })
  },

  pullUserSettings ({ commit }) {
    return new Promise<void>((resolve, reject) => {
      UserService.getUserSettings().then((result) => {
        commit('setUserSettings', result.data)
        resolve()
      }).catch((error) => {
        vuem.$log.debug('get user settings failed', error)
        reject(error)
      })
    })
  },

  pullUser ({ commit }) {
    return new Promise<void>((resolve, reject) => {
      AuthService.getCurrentUser().then((userData: AxiosResponse<User>) => {
        const isAdmin = userData.data.roles?.some(value => value.name === 'Admin')
        commit('setIsAdmin', isAdmin)
        commit('setUserRoles', userData.data.roles)

        resolve()
      }).catch((error) => {
        vuem.$log.debug('get current user failed', error)
        reject(error)
      })
    })
  },

  pullUserGroups ({ commit }) {
    return new Promise<void>((resolve, reject) => {
      GroupService.getUserGroups().then((groupResponse) => {
        commit('setUserGroups', groupResponse.data)
        resolve()
      }).catch((error) => {
        vuem.$log.debug('fetching user groups failed', error)
        reject(error)
      })
    })
  },

  createRoutes ({ commit }) {
    return new Promise<void>((resolve, reject) => {
      // TODO: Improve this logic
      // This code is used to forward user to actual page while routes are loading from the api
      const currentSite = router.currentRoute.params.pathMatch
      let forward = false

      const resolved = router.resolve({ path: currentSite })
      if (resolved.resolved.name === '404') {
        forward = true
        router.push({ name: 'loading' }).then()
      }

      if (store.getters.isAuthenticated) {
        AppService.getAll().then((response) => {
          response.data.forEach(function (r: any) {
            if (r.routerSettings != null) {
              const resolved = router.resolve({ path: r.routerSettings.path })
              if (resolved.resolved.name === '404') {
                router.addRoute({
                  path: r.routerSettings.path,
                  name: r.routerSettings.name,
                  meta: { appId: r.id }, // TODO: Type this, when possible (> vue 3)
                  component: () => import('@/apps/'.concat(r.routerSettings.component))
                })
              }
            }
          })

          if (forward) {
            router.push(currentSite)
          }

          resolve()
        }).catch((error) => {
          reject(error)
        })
      } else {
        resolve()
      }
    })
  }
}

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
  isBasicUser: (state) => {
    return state.userRoles.length === 0
  }
}

export const AuthStore: Module<AuthState, RootState> = {
  state,
  getters,
  actions,
  mutations
}
