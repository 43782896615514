import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import CoOccurrenceOfTaxaIcon from '@/components/icons/CoOccurrenceOfTaxaIcon.vue'
import CommunityStructureIcon from '@/components/icons/CommunityStructureIcon.vue'
import CorrelationsOfTaxaAndFunctionsIcon from '@/components/icons/CorrelationsOfTaxaAndFunctionsIcon.vue'
import DiversityAnalysisIcon from '@/components/icons/DiversityAnalysisIcon.vue'
import FunctionalAnnotationsIcon from '@/components/icons/FunctionalAnnotationsIcon.vue'
import MostAbundantTaxaIcon from '@/components/icons/MostAbundantTaxaIcon.vue'

Vue.use(Vuetify)

const opts = {
  icons: {
    values: {
      CoOccurrenceOfTaxa: {
        component: CoOccurrenceOfTaxaIcon
      },
      CommunityStructure: {
        component: CommunityStructureIcon
      },
      CorrelationsOfTaxaAndFunctions: {
        component: CorrelationsOfTaxaAndFunctionsIcon
      },
      DiversityAnalysis: {
        component: DiversityAnalysisIcon
      },
      FunctionalAnnotations: {
        component: FunctionalAnnotationsIcon
      },
      MostAbundantTaxa: {
        component: MostAbundantTaxaIcon
      }
    }
  },
  // NOTE: Do not forget to adjust SCSS vars in "variables.scss" in case this changes!
  theme: {
    themes: {
      light: {
        primary: '#13A4C2',
        secondary: '#4CBF71',
        accent: '#424750',
        // error: '#FF0000',
        info: '#D0F9E5',
        admin: '#bc374c',
        darkgreen: '#00892C',
        showcase: '#EFFF8E',
        showcase2: '#9B9028'
        // success: '#4CAF50',
        // warning: '#FFC107',
        // anchor: '#D5D5D5',
      }
    }
  }

  // Morpheus Logo Colors:
  // #15a6c5
  // #116a95
  // #2aaaa8
  // #63be80
}

export default new Vuetify(opts)
