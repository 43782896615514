import axios from 'axios'
import store from '@/store'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'

const baseURL = process.env.VUE_APP_BASEURL

const ApiService = axios.create({
  baseURL: baseURL
})

ApiService.interceptors.request.use(
  (config) => {
    const token = store.state.auth.token

    if (token && config?.headers) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },

  (error) => {
    // console.log("reqE", error)
    return Promise.reject(error)
  }
)

ApiService.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (typeof error === 'undefined' || error == null) {
      store.dispatch('authLogout')
      return
    }

    const stat = error.response.status

    switch (stat) {
      case 500:
        store.dispatch('notifications/showError', { text: i18n.t('serverError').toString() })
        break
      case 401:
        store.dispatch('authLogout')
        router.push({ name: 'login' }).catch(error => {
          if (error.name !== 'NavigationDuplicated') {
            throw error
          }
        })
        break
      default:
        break
    }

    return Promise.reject(error.response)
  })

export { ApiService }
