import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { PageParams, Project, SortParams } from '@/types/Types'
import { Operation } from 'fast-json-patch/module/core'

export const ProjectService = {
  getProjects (params: PageParams): AxiosPromise {
    return ApiService({
      url: '/projects',
      method: 'get',
      params: params
    })
  },

  getAllProjects (params: SortParams): AxiosPromise {
    return ApiService({
      url: '/projects/all',
      method: 'get',
      params: params
    })
  },

  getProjectById (projectId: string): AxiosPromise {
    return ApiService({
      url: '/project/' + projectId,
      method: 'get'
    })
  },

  updateProject (projectId: string, patchData: Operation[]): AxiosPromise {
    return ApiService({
      url: '/project/' + projectId,
      method: 'patch',
      data: patchData,
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    })
  },

  createProject (projectData: Project, ownerGroupId: string): AxiosPromise {
    return ApiService({
      url: '/project/create/' + ownerGroupId,
      method: 'post',
      data: projectData
    })
  },

  deleteProject (projectId: string): AxiosPromise {
    return ApiService({
      url: '/project/' + projectId,
      method: 'delete'
    })
  },

  updateDescription (projectId: string, description: string, addNew = false) {
    const data: Operation[] = []

    data.push({
      op: addNew ? 'add' : 'replace',
      path: '/description',
      value: description
    })

    return new Promise<void>((resolve, reject) => {
      this.updateProject(projectId, data).then(() => {
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
