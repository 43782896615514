import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'

export const RoleService = {
  getAllRoles (): AxiosPromise {
    return ApiService({
      url: '/roles',
      method: 'get'
    })
  }
}
