
import Component from 'vue-class-component'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BaseApp, Project, ProjectType } from '@/types/Types'
import { NavBarType, ProjectGetType } from '@/types/Enums'
import { AdminAppList } from '@/types/Admin'

const ProjectNavigationBoxProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  },
  computed: {
    ...mapGetters([
      'selectedProjectName',
      'isItemSelected'
    ])
  }
})
export default class ProjectNavigationBox extends ProjectNavigationBoxProps {
  projectsMini = true
  settingsMini = true
  adminApps: Array<BaseApp> = AdminAppList

  get includePublic (): boolean {
    return this.$store.state.projects.includePublic
  }

  set includePublic (value: boolean) {
    this.$store.commit('projects/setIncludePublic', value)
  }

  get barBackgroundColor () {
    switch (this.type) {
      case NavBarType.Admin:
        return 'admin-background'
      case NavBarType.Analysis:
        return 'analysis-bar'
      case NavBarType.Data:
        return 'data-background'
      default:
        return 'data-background'
    }
  }

  get selectedMyProjectNumber (): number {
    if (this.projects && this.projectId) {
      return this.projects.map(project => project.id).indexOf(this.projectId)
    } else {
      return -1
    }
  }

  set selectedMyProjectNumber (projectNumber: number) {
    //
  }

  get selectedPublicProjectNumber (): number {
    if (this.publicProjects && this.projectId) {
      return this.publicProjects.map(project => project.id).indexOf(this.projectId)
    } else {
      return -1
    }
  }

  get selectedSettingsNumber (): number {
    if (this.projectType?.dataApps && this.$route.name) {
      return this.projectType.dataApps.map(app => app.routerSettings.name).indexOf(this.$route.name)
    } else {
      return -1
    }
  }

  set selectedSettingsNumber (value: number) {
    // this setter is only for compatibility
  }

  get selectedAnalysisNumber (): number {
    if (this.projectType?.analysisApps && this.$route.name) {
      return this.projectType.analysisApps.map(app => app.routerSettings.name).indexOf(this.$route.name)
    } else {
      return -1
    }
  }

  set selectedAnalysisNumber (value: number) {
    // this setter is only for compatibility
  }

  get selectedAdminNumber (): number {
    if (this.$route.name) {
      return this.adminApps.map(app => app.routerSettings.name).indexOf(this.$route.name)
    } else {
      return -1
    }
  }

  set selectedAdminNumber (value: number) {
    // this setter is only for compatibility
  }

  get projectId (): string | null {
    return this.$store.state.projects.selectedProjectId
  }

  get project (): Project | null {
    return this.$store.state.projects.selectedProject
  }

  get myProjects (): Array<Project> {
    return this.$store.state.projects.myProjects
  }

  get publicProjects (): Array<Project> {
    return this.$store.state.projects.publicProjects
  }

  get projects () {
    if (this.includePublic && !this.isBasicUser) {
      return [...this.myProjects, ...this.publicProjects]
    } else {
      return this.myProjects
    }
  }

  get hasPublicProjects () {
    return !!this.publicProjects && this.publicProjects.length > 0
  }

  get projectType (): ProjectType | null {
    return this.$store.state.projects.selectedProjectType
  }

  get type (): NavBarType {
    return this.$store.state.projects.navBarType
  }

  get showDefault (): boolean {
    return this.type === NavBarType.Default
  }

  get showSettings (): boolean {
    return this.type === NavBarType.Data
  }

  get showAnalysis (): boolean {
    return this.type === NavBarType.Analysis
  }

  get showAdmin (): boolean {
    return this.type === NavBarType.Admin
  }

  get isBasicUser () {
    return this.$store.getters.isBasicUser
  }

  get hideAnalysis (): boolean {
    if (this.project?.projectType?.amountOfAnalysisApps) {
      return this.project.projectType.amountOfAnalysisApps <= 0
    } else {
      return true
    }
  }

  get toggleProjectMiniIcon (): string {
    return this.projectsMini ? 'mdi-chevron-right' : 'mdi-close'
  }

  get toggleSettingsMiniIcon (): string {
    return this.settingsMini ? 'mdi-chevron-right' : 'mdi-chevron-left'
  }

  toggleProjectsMini (): void {
    this.projectsMini = !this.projectsMini

    // close settings drawer if projects are opened
    if (!this.projectsMini) {
      this.settingsMini = true
    }
  }

  toggleSettingsMini (): void {
    this.settingsMini = !this.settingsMini

    // close projects drawer if settings are opened
    if (!this.settingsMini) {
      this.projectsMini = true
    }
  }

  showSettingsSelected (): void {
    if (this.showAnalysis && this.$route.params.projectId) {
      this.changeRoute('project-data', { projectId: this.$route.params.projectId })
    } else {
      this.toggleSettingsMini()
    }
  }

  showAnalysisSelected (): void {
    if (this.showSettings && this.$route.params.projectId) {
      this.changeRoute('project-analysis', { projectId: this.$route.params.projectId })
    } else {
      this.toggleSettingsMini()
    }
  }

  openProject (project: Project) {
    if (project.id) {
      if (!!project?.projectType &&
          project.projectType.amountOfAnalysisApps != null &&
          project.projectType.amountOfAnalysisApps <= 0) {
        this.changeRoute('project-data', { projectId: project.id })
      } else {
        this.changeRoute('project-analysis', { projectId: project.id })
      }
    }
  }

  openMyProjects () {
    this.changeRoute('my-projects')
  }

  changeRoute (routeName: string, params?: Record<string, string>): void {
    if (params && Object.keys(params).length) {
      this.$router.push({ name: routeName, params: params }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.$router.push({ name: routeName }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }

  mounted () {
    this.$store.dispatch('projects/refreshProjects')
  }
}
