
import Vue from 'vue'
import Component from 'vue-class-component'

const NotFoundProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  }
})
export default class NotFound extends NotFoundProps {
  get currentRoutePath () {
    return this.$route.params.pathMatch
  }

  mounted () {
    this.$log.debug('Route not found!! ' + this.currentRoutePath)
  }
}
