
import Vue from 'vue'
import Component from 'vue-class-component'
import ProjectNavigationBox from '@/components/ProjectNavigationBox.vue'
import { Project, ProjectType } from '@/types/Types'
import { NavBarType } from '@/types/Enums'
import LoadingCard from '@/components/LoadingCard.vue'
import { ProjectService } from '@/services/ProjectService'
import { ProjectTypeService } from '@/services/ProjectTypeService'
import { ProjectUtils } from '@/utils/ProjectUtils'

const ProjectDataViewProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    LoadingCard,
    ProjectNavigationBox
  }
})
export default class ProjectDataView extends ProjectDataViewProps {
  loading = true

  get project (): Project | null {
    return this.$store.state.projects.selectedProject
  }

  get projectType (): ProjectType | null {
    return this.$store.state.projects.selectedProjectType
  }

  get selectedProject (): Project | null {
    return this.$store.state.projects.selectedProject
  }

  get logoName ():string {
    return ProjectUtils.getLogoName(this.project)
  }

  get showLogo () {
    return this.logoName !== 'computomics_logo_no_claim.svg'
  }

  changeRoute (routeName: string, params?: Record<string, string>): void {
    if (params && Object.keys(params).length) {
      this.$router.push({ name: routeName, params: params }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.$router.push({ name: routeName }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    }
  }

  mounted (): void {
    this.$store.commit('projects/setNavBarType', NavBarType.Data)

    if (this.$route.params.projectId) {
      this.$store.commit('projects/setSelectedProjectId', this.$route.params.projectId)
      this.$store.dispatch('projects/getSelectedProjectInfo').then(() => {
        this.loading = false
      }).catch((error) => {
        this.$log.debug('Could not load project from server', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.ProjectDataView.loadError').toString()
        })
      })
    } else {
      // if no projectID is given route back to projects
      this.$router.push({ name: 'dashboard' })
    }
  }
}
