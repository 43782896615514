
import Vue from 'vue'
import Component from 'vue-class-component'
import i18n from '@/plugins/i18n'

const LoadingCardProps = Vue.extend({
  props: {
    text: { type: String, default: i18n.t('global.loading') },
    elevation: { type: Number, default: 2 }
  }
})

@Component
export default class LoadingCard extends LoadingCardProps {
}
