
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue'
import ProjectCredentialsDialog from '@/components/ProjectCredentialsDialog.vue'
import * as jsonpatch from 'fast-json-patch'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import { VForm } from '@/$refs'
import { Group, PageParams, Project, ProjectCredentials, ProjectType, SortParams, User } from '@/types/Types'
import { Observer } from 'fast-json-patch/module/duplex'
import { ProjectService } from '@/services/ProjectService'
import { GroupService } from '@/services/GroupService'
import { DataStorageService } from '@/services/DataStorageService'
import { ProjectGetType } from '@/types/Enums'

const ProjectTableProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    SimpleConfirmDialog,
    ProjectCredentialsDialog
  }
})
export default class ProjectTable extends ProjectTableProps {
  EMPTY_PROJECT = {
    name: '',
    publicVisible: false
  } as Project

  maxGroupDisplay = 4
  projects = []
  groups = []
  selectedGroups: Array<Group> = []
  createProjectDialog = false
  projectIdMarkedForChange: string | null = null
  selectedProjectObserver: Observer<Project> | null = null
  editProject = false
  selectedProject: Project = JSON.parse(JSON.stringify(this.EMPTY_PROJECT))
  showSecretDialog = false
  showDeleteDialog = false
  showCredentialsDialog = false
  projectCredentials = {} as ProjectCredentials
  search = ''

  required = [
    (v: any) => !!v || this.$t('required')
  ]

  requiredGroup = [
    (v: any) => !!v || this.$t('required'),
    (v: any) => (Array.isArray(v) && v.length !== 0) || this.$t('required')
  ]

  loading = true
  options = {
    sortBy: ['name'],
    sortDesc: [false],
    page: 1,
    itemsPerPage: -1
  }

  items = []
  headers = [
    {
      text: this.$t('adminSettings.projectManagement.projectTable.header.projectName'),
      value: 'name',
      align: 'left'
    },
    {
      text: this.$t('adminSettings.projectManagement.projectTable.header.groups'),
      value: 'groups',
      sortable: false,
      align: 'left'
    },
    {
      text: this.$t('adminSettings.projectManagement.projectTable.header.groupOwner'),
      value: 'ownerGroup',
      sortable: false,
      align: 'left'
    },
    {
      text: this.$t('adminSettings.projectManagement.projectTable.header.visibility'),
      value: 'publicVisible',
      align: 'left'
    },
    {
      text: this.$t('adminSettings.projectManagement.projectTable.header.actions'),
      value: 'action',
      sortable: false,
      align: 'right',
      width: '120px'
    }
  ]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get dialogHeader () {
    if (this.editProject) {
      return this.$t('adminSettings.projectManagement.editProject')
    } else {
      return this.$t('adminSettings.projectManagement.createProject')
    }
  }

  get projectTypes () {
    return this.$store.state.projects.projectTypes
  }

  getGroupNames (groups: Array<Group>): string {
    if (groups) {
      if (groups.length > this.maxGroupDisplay) {
        return groups.slice(0, this.maxGroupDisplay).map(item => item?.name || '').join(', ')
      } else {
        return groups.map(item => item?.name || '').join(', ')
      }
    } else {
      return ''
    }
  }

  getProjectHasParent (project: Project) {
    return project.parents && project.parents.length > 0
  }

  getProjects () {
    return new Promise<void>((resolve, reject) => {
      let sortOrder = 'asc'
      this.loading = true

      const {
        sortBy,
        sortDesc
      } = this.options

      if (sortDesc[0]) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }

      const params: SortParams = {
        sort: sortBy + ',' + sortOrder
      }

      ProjectService.getAllProjects(params).then((response) => {
        this.projects = response.data
        resolve()
      }).catch((error) => {
        this.$log.debug('Error loading projects from server: ', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('projectLoadError').toString()
        })
        reject(error)
      }).finally(() => {
        this.loading = false
      })
    })
  }

  getAllGroupNames () {
    return new Promise<void>((resolve, reject) => {
      const params: SortParams = {
        sort: 'name,asc'
      }

      GroupService.getAllGroupNames(params).then((response) => {
        this.groups = response.data
        resolve()
      }).catch((error) => {
        this.$log.debug('Error loading groups from server: ', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('groupLoadError').toString()
        })

        reject(error)
      })
    })
  }

  @Watch('showCredentialsDialog')
  onShowCredentialsDialogChanged () {
    if (!this.showCredentialsDialog) {
      this.projectCredentials = {} as ProjectCredentials
    }
  }

  removeSelectedGroup (group: Group) {
    const index = this.selectedGroups.map(item => item.id).indexOf(group.id)
    if (index >= 0) {
      this.selectedGroups.splice(index, 1)
    }
  }

  confirmRegenerateSecret (id: string) {
    this.projectIdMarkedForChange = id
    this.showSecretDialog = true
  }

  closeSecretDialog () {
    this.projectIdMarkedForChange = null
    this.showSecretDialog = false
  }

  openEditProjectDialog (project: Project) {
    // copy the object to the selected user
    this.selectedProject = JSON.parse(JSON.stringify(project))

    if (this.selectedProject && project.id) {
      // add an observer for the patch operation
      this.selectedProjectObserver = jsonpatch.observe(this.selectedProject)

      this.selectedGroups = JSON.parse(JSON.stringify(project.groups)) ?? []
      this.editProject = true
      this.projectIdMarkedForChange = project.id
      this.createProjectDialog = true
    }
  }

  openNewProjectDialog () {
    this.editProject = false
    this.createProjectDialog = true
  }

  resetProjectDialog () {
    this.form.resetValidation()
    this.resetSelectedProject()

    this.createProjectDialog = false
    this.projectIdMarkedForChange = null
  }

  resetSelectedProject () {
    this.selectedProjectObserver = null
    this.selectedProject = JSON.parse(JSON.stringify(this.EMPTY_PROJECT))

    this.selectedGroups = []
  }

  confirmDeleteProject (id: string) {
    this.projectIdMarkedForChange = id
    this.showDeleteDialog = true
  }

  closeDeleteDialog () {
    this.projectIdMarkedForChange = null
    this.showDeleteDialog = false
  }

  saveProject () {
    if (this.editProject) {
      this.updateProject()
    } else {
      this.createProject()
    }
  }

  refreshProjects () {
    this.$store.dispatch('projects/refreshProjects')
  }

  updateProject () {
    let oldGroupIds: any = []
    let newGroupIds: any = []

    if (this.form.validate() && this.selectedProject && this.selectedProjectObserver) {
      if (this.selectedProject.groups && this.selectedProject.groups.length > 0) {
        oldGroupIds = this.selectedProject.groups.filter((x: any) => !!x).map((group: any) => group.id)
      }

      if (this.selectedGroups && this.selectedGroups.length > 0) {
        newGroupIds = this.selectedGroups.filter((x: any) => !!x).map((group: any) => group.id)
      }

      const patch = jsonpatch.generate(this.selectedProjectObserver)

      if (JSON.stringify(oldGroupIds) !== JSON.stringify(newGroupIds)) {
        // rekey ids
        newGroupIds = newGroupIds.map((groupId: string) => {
          return { id: groupId }
        })
        patch.push({
          op: 'replace',
          path: '/groups',
          value: newGroupIds
        })
      }

      if (patch.length > 0 && this.projectIdMarkedForChange && this.selectedProject.ownerGroup?.id) {
        this.loading = true

        ProjectService.updateProject(this.projectIdMarkedForChange, patch).then(() => {
          this.getProjects()
          this.$store.dispatch('notifications/showSuccess', {
            text: this.$t('projectUpdateSuccess').toString()
          })

          // Refresh projects in Navbox
          this.refreshProjects()
        }).catch((error) => {
          this.$log.debug('Error updating project on server: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))
          this.$store.dispatch('notifications/showError', {
            text: this.$t('projectUpdateFailed')
          })
        }).finally(() => {
          this.loading = false
          this.resetProjectDialog()
        })
      } else {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('projectUpdateNotNeeded').toString()
        })

        this.resetProjectDialog()
      }
    }
  }

  createProject () {
    if (this.form.validate() && this.selectedProject && this.selectedProject.ownerGroup?.id) {
      this.loading = true

      // this is done to unifi selectedProject and selectedGroups
      const projectData = {
        id: this.selectedProject.id,
        name: this.selectedProject.name,
        description: this.selectedProject.description,
        ownerGroup: this.selectedProject.ownerGroup,
        groups: this.selectedGroups,
        projectType: this.selectedProject.projectType,
        publicVisible: this.selectedProject.publicVisible
      }

      ProjectService.createProject(projectData, this.selectedProject.ownerGroup.id).then((response) => {
        this.getProjects()

        // update credentials
        this.projectCredentials = response.data

        // display credentials dialog
        this.showCredentialsDialog = true

        // show success notification
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('projectCreatedSuccess').toString()
        })

        // Refresh projects in Navbox
        this.refreshProjects()
      }).catch((error) => {
        this.$log.debug('Error creating project on server: ', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('projectCreatedFailed')
        })
      }).finally(() => {
        this.loading = false
        this.resetProjectDialog()
      })
    }
  }

  deleteProject () {
    this.loading = true

    if (this.projectIdMarkedForChange) {
      ProjectService.deleteProject(this.projectIdMarkedForChange).then(() => {
        this.getProjects()
        this.projectIdMarkedForChange = null

        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('projectDeletedSuccess').toString()
        })

        this.showDeleteDialog = false

        // Refresh projects in Navbox
        this.refreshProjects()
      }).catch((error) => {
        this.$log.debug('Error deleting project from server: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))

        this.$store.dispatch('notifications/showError', {
          text: this.$t('projectDeletedFailed')
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }

  regenerateSecret () {
    this.loading = true

    if (this.projectIdMarkedForChange != null) {
      DataStorageService.regenerateSecret(this.projectIdMarkedForChange).then((response) => {
        this.projectIdMarkedForChange = null

        // update credentials
        this.projectCredentials = response.data

        // display credentials
        this.showCredentialsDialog = true

        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('regenerateSecretSuccess').toString()
        })

        this.showSecretDialog = false
      }).catch((error) => {
        this.$log.debug('Error regenerating secret: ', (error.data?.error ? error.data.error : (error.data?.message ? error.data.message : error.status)))

        this.$store.dispatch('notifications/showError', {
          text: this.$t('regenerateSecretFailed')
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }

  mounted () {
    const promises: any = []

    promises.push(this.getAllGroupNames())
    promises.push(this.getProjects())

    Promise.allSettled(promises).then((results: any) => {
      this.$watch(() => this.options, () => {
        this.getProjects()
      })
    })
  }
}
