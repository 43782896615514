import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import NotFound from '@/components/NotFound.vue'
import ProjectDataView from '@/views/ProjectDataView.vue'
import ProjectAnalysis from '@/views/ProjectAnalysis.vue'
import LoadingCard from '@/components/LoadingCard.vue'
import UserProfileView from '@/views/user/UserProfileView.vue'
import LoginView from '@/views/LoginView.vue'
import ProjectsOverview from '@/views/ProjectsOverview.vue'
import UserManagement from '@/views/admin/UserManagement.vue'
import GroupManagement from '@/views/admin/GroupManagement.vue'
import ProjectManagement from '@/views/admin/ProjectManagement.vue'
import AdminApps from '@/views/admin/AdminApps.vue'
import AdminSettings from '@/views/admin/AdminSettings.vue'
import LegalTerms from '@/views/legal/LegalTerms.vue'
import UserSettingsView from '@/views/user/UserSettingsView.vue'
import PasswordReset from '@/views/user/PasswordReset.vue'
import RequestPasswordReset from '@/views/user/RequestPasswordReset.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login/:logout?',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'dashboard',
    redirect: { name: 'my-projects' }
  },
  {
    path: '/projects',
    name: 'my-projects',
    component: ProjectsOverview
  },
  {
    path: '/project/data/:projectId',
    name: 'project-data',
    component: ProjectDataView
  },
  {
    path: '/project/analysis/:projectId',
    name: 'project-analysis',
    component: ProjectAnalysis
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfileView
  },
  {
    path: '/user-settings',
    name: 'user-settings',
    component: UserSettingsView
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: { name: 'admin-apps' },
    component: AdminSettings,
    children: [
      {
        path: '/admin/apps/',
        name: 'admin-apps',
        component: AdminApps
      },
      {
        path: '/admin/user/',
        name: 'user-admin',
        component: UserManagement
      },
      {
        path: '/admin/groups/',
        name: 'group-admin',
        component: GroupManagement
      },
      {
        path: '/admin/projects/',
        name: 'project-admin',
        component: ProjectManagement
      }
    ]
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingCard
  },
  {
    path: '/terms',
    name: 'terms',
    component: LegalTerms
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    component: PasswordReset
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: RequestPasswordReset
  },
  {
    path: '*',
    name: '404',
    meta: { icon: '' },
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages: Array<string> = ['login', 'terms', 'password-reset', 'request-password-reset']

  // route every non login page to page if not auth
  if (!store.getters.isAuthenticated && !publicPages.includes(to.name || '')) {
    if (to.name === 'terms') {
      router.push({ name: 'terms' })
    } else {
      router.push({ name: 'login' })
    }
    // if auth never reach login
  } else if (store.getters.isAuthenticated && to.name === 'login') {
    router.push({ name: 'root' })
  }
  // else if( store.getters.isAuthenticated && (store.getters['auth/timeForNewToken'](DateTime.now()) || !store.getters['auth/user'])) {
  //   store.dispatch('auth/renewToken').catch(() => {
  //     store.dispatch('auth/logout')
  //   })
  // }

  next()
})

export default router
