
import Vue from 'vue'
import Component from 'vue-class-component'
import { mail, matchPassword, required, safePw } from '@/utils/InputRules'
import { VForm } from '@/$refs'
import { AuthService } from '@/services/AuthService'

const PasswordResetProps = Vue.extend({
  props: {
    //
  }
})

@Component
export default class PasswordReset extends PasswordResetProps {
  passwordReset = false
  email = ''
  password1 = ''
  password2 = ''
  emailRule = [required(), mail()]
  passwordRule = [required(), safePw()]
  passwordRepeatRules = [required(), matchPassword(this.matchesPassword)]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get token (): string | null {
    return this.$route.params.token
  }

  matchesPassword (password: string): boolean {
    return this.password1 === password
  }

  resetPassword () {
    if (this.form.validate() && this.token) {
      AuthService.resetPassword(this.token, this.password1, this.email).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.passwordReset.reset.success').toString()
        })

        this.passwordReset = true
      }).catch((error) => {
        if (error.status === 403) {
          this.$log.debug('Password reset request failed, invalid token.', error)
          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.passwordReset.reset.invalidToken').toString()
          })
        } else {
          this.$log.debug('Password reset request failed', error)
          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.passwordReset.reset.failed').toString()
          })
        }
      })
    }
  }

  forwardToDashboard () {
    this.$router.push({ name: 'dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted () {
    if (!this.token) {
      // if no token is given forward to dashboard
      this.forwardToDashboard()
    }
  }
}
