import { Group, PageParams, SortParams } from '@/types/Types'
import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { Operation } from 'fast-json-patch/module/core'

export const GroupService = {
  getUserGroups (): AxiosPromise {
    return ApiService({
      url: '/groups/user',
      method: 'get'
    })
  },

  getGroups (params: PageParams): AxiosPromise {
    return ApiService({
      url: '/groups',
      method: 'get',
      params
    })
  },

  getAllGroups (params: SortParams): AxiosPromise {
    return ApiService({
      url: '/groups/all',
      method: 'get',
      params
    })
  },

  getAllGroupNames (params?: SortParams): AxiosPromise {
    return ApiService({
      url: '/groups/allNames',
      method: 'get',
      params
    })
  },

  updateGroup (groupId: string, patchData: Operation[]): AxiosPromise {
    return ApiService({
      url: '/group/' + groupId,
      method: 'patch',
      data: patchData,
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    })
  },

  createGroup (groupData: Group): AxiosPromise {
    return ApiService({
      url: '/group/create',
      method: 'post',
      data: groupData
    })
  },

  deleteGroup (groupId: string): AxiosPromise {
    return ApiService({
      url: '/group/' + groupId,
      method: 'delete'
    })
  }
}
