import { PipelineState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { PipelineService } from '@/services/PipelineService'
import router from '@/plugins/router'
import Vue from 'vue'
import i18n from '@/plugins/i18n'
import store from '@/store'

const state: PipelineState = {
  loading: false,
  allPipelines: [],
  allPipelineRuns: []
}

const mutations: MutationTree<PipelineState> = {
  setLoading: (state, value) => {
    Vue.set(state, 'loading', value)
  },

  setAllPipelines: (state, value) => {
    Vue.set(state, 'allPipelines', value)
  },

  setAllPipelineRuns: (state, value) => {
    Vue.set(state, 'allPipelineRuns', value)
  }
}

const actions: ActionTree<PipelineState, RootState> = {
  reset ({ commit }) {
    commit('setAllPipelines', [])
    commit('setAllRuns', [])
  },

  update ({ commit, dispatch }) {
    commit('setLoading', true)
    const isUserAdmin = store.state.auth.isAdmin
    const currentAppId = router.currentRoute.meta?.appId

    // fetch pipelines
    const pipelineReq = (isUserAdmin)
      ? PipelineService.getAllPipelines()
      : PipelineService.getPipelinesForApp(currentAppId)

    pipelineReq
      .then(pipelineResp => {
        // fetch runs
        const pipelineRunsReq = (isUserAdmin)
          ? PipelineService.getAllRuns()
          : PipelineService.getRunsForCurrentUserGroup()

        return pipelineRunsReq
          // update pipelines and runs
          .then(runsResp => {
            commit('setAllPipelines', pipelineResp.data ?? [])
            commit('setAllPipelineRuns', runsResp.data ?? [])
          }).catch(e => {
            Vue.$log.debug('Error fetching pipeline runs: ', e)
            dispatch('notifications/showError',
              { text: `${i18n.t('pipelineRunList.error.fetchRunsError')}: ${e}` },
              { root: true })
          })
      })
      .catch(e => {
        Vue.$log.debug(`Error fetching pipelines for app ${currentAppId}: `, e)
        dispatch('notifications/showError',
          { text: `${i18n.t('pipelineRunList.error.fetchPipelinesError')}: ${e}` },
          { root: true })
      }).finally(() => commit('setLoading', false))
  }
}

const getters: GetterTree<PipelineState, RootState> = {}

export const PipelineStore: Module<PipelineState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
