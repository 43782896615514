export const AdminAppList = [
  {
    name: 'adminSettings.userManagement.title',
    description: 'adminSettings.userManagement.description',
    icon: 'mp-account',
    routerSettings: {
      name: 'user-admin'
    }
  },
  {
    name: 'adminSettings.groupManagement.title',
    description: 'adminSettings.groupManagement.description',
    icon: 'mp-accounts',
    routerSettings: {
      name: 'group-admin'
    }
  },
  {
    name: 'adminSettings.projectManagement.title',
    description: 'adminSettings.projectManagement.description',
    icon: 'mp-project',
    routerSettings: {
      name: 'project-admin'
    }
  }
]
