import { render, staticRenderFns } from "./LegalTerms.vue?vue&type=template&id=379c7dab&scoped=true&"
import script from "./LegalTerms.vue?vue&type=script&lang=ts&"
export * from "./LegalTerms.vue?vue&type=script&lang=ts&"
import style0 from "./LegalTerms.vue?vue&type=style&index=0&id=379c7dab&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379c7dab",
  null
  
)

export default component.exports