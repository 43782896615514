import { MiscUtils } from '@/utils/MiscUtils'
import i18n from '@/plugins/i18n'

export function required (): (v: unknown) => boolean|string {
  return (v) => (!!v || v === 0) || i18n.t('global.validation.required').toString()
}
export function mail (): (v: unknown) => boolean|string {
  return (v) => {
    if (typeof v === 'string') {
      const mailRegex = MiscUtils.getMailRegex()
      return mailRegex.test(v) || i18n.t('global.validation.emailRequired').toString()
    } else {
      return i18n.t('global.validation.error').toString()
    }
  }
}

export function safePw (): (v: unknown) => boolean|string {
  return (v) => {
    if (typeof v === 'string') {
      const mailRegex = MiscUtils.getPasswordLengthRegex()
      return mailRegex.test(v) || i18n.t('global.validation.passwordWeak').toString()
    } else {
      return i18n.t('global.validation.error').toString()
    }
  }
}

export function matchPassword (matchPasswordFunction: (password: string) => boolean): (v: unknown) => boolean|string {
  return (v) => {
    if (typeof v === 'string') {
      return matchPasswordFunction(v) || i18n.t('global.validation.passwordsNotIdentical').toString()
    } else {
      return i18n.t('global.validation.error').toString()
    }
  }
}
