import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { PageParams, SortParams, User } from '@/types/Types'
import { Operation } from 'fast-json-patch/module/core'

export const UserService = {
  getAllUsers (params: SortParams): AxiosPromise {
    return ApiService({
      url: '/users/all',
      method: 'get',
      params: params
    })
  },

  getAllUserEmails (params?: SortParams): AxiosPromise {
    return ApiService({
      url: '/users/allMails',
      method: 'get',
      params: params
    })
  },

  getUsers (params: PageParams): AxiosPromise {
    return ApiService({
      url: '/users',
      method: 'get',
      params: params
    })
  },

  deleteUser (userId: string): AxiosPromise {
    return ApiService({
      url: '/user/' + userId,
      method: 'delete'
    })
  },

  patchUser (userId: string, patchData: Operation[]): AxiosPromise {
    return ApiService({
      url: '/user/' + userId,
      method: 'patch',
      data: patchData,
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    })
  },

  registerUser (userData: User): AxiosPromise {
    return ApiService({
      url: '/auth/register',
      method: 'post',
      data: userData
    })
  },

  getUserSettings  (): AxiosPromise {
    return ApiService({
      url: '/userSettings',
      method: 'get'
    })
  },

  patchUserSettings (patchData: Operation[]): AxiosPromise {
    return ApiService({
      url: '/userSettings',
      method: 'patch',
      data: patchData,
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    })
  }
}
