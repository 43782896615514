import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'

export const AppService = {
  getAll (): AxiosPromise {
    return ApiService({
      url: '/apps',
      method: 'get'
    })
  }
}
