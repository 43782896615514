
import Component from 'vue-class-component'
import Vue from 'vue'
import { NavBarType } from '@/types/Enums'
import LoadingCard from '@/components/LoadingCard.vue'
import GroupTable from '@/components/admin/GroupTable.vue'

const GroupManagementProps = Vue.extend({
  props: {
    //
  }
})
@Component({
  components: { GroupTable, LoadingCard }
})
export default class GroupManagement extends GroupManagementProps {
  loading = false

  mounted (): void {
    this.$store.commit('projects/setNavBarType', NavBarType.Admin)
  }
}
