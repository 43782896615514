import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/types/StoreTypes'
import { AuthStore } from '@/store/modules/AuthStore'
import createPersistedState from 'vuex-persistedstate'
import { NotificationStore } from '@/store/modules/NotificationStore'
import { SubsetCombineStore } from '@/store/modules/SubsetCombineStore'
import { ProjectStore } from '@/store/modules/ProjectStore'
import { PipelineStore } from '@/store/modules/PipelineStore'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    auth: AuthStore,
    notifications: NotificationStore,
    subsetCombine: SubsetCombineStore,
    projects: ProjectStore,
    pipelines: PipelineStore
  },
  // persist state of attachedTo, to be able to refresh the page
  plugins: [createPersistedState({
    paths: ['auth.isAdmin', 'auth.token', 'auth.userGroups', 'auth.userRoles', 'auth.userSettings']
  })]
}

export default new Vuex.Store<any>(store)
