
import Component from 'vue-class-component'
import Vue from 'vue'
import { ProjectCredentials } from '@/types/Types'

const ProjectCredentialProps = Vue.extend({
  props: {
    showDialog: { type: Boolean, required: false, default: false },
    projCredentials: { type: Object, required: false, default: {} as ProjectCredentials }
  }
})

@Component({})
export default class ProjectCredentialsDialog extends ProjectCredentialProps {
  get isOpen (): boolean {
    return this.showDialog
  }

  set isOpen (value) {
    this.$emit('update:showDialog', value)
  }

  get projectCredentials (): ProjectCredentials {
    return this.projCredentials
  }
}

