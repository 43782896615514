
import Component from 'vue-class-component'
import Vue from 'vue'
import { NavBarType } from '@/types/Enums'
import LoadingCard from '@/components/LoadingCard.vue'
import UserTable from '@/components/admin/UserTable.vue'

const UserManagementProps = Vue.extend({
  props: {
    //
  }
})
@Component({
  components: { UserTable, LoadingCard }
})
export default class UserManagement extends UserManagementProps {
  mounted (): void {
    this.$store.commit('projects/setNavBarType', NavBarType.Admin)
  }
}
