
import Vue from 'vue'
import Component from 'vue-class-component'

const LegalTermsProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: {
    //
  }
})
export default class LegalTerms extends LegalTermsProps {
  mounted (): void {
    //
  }
}
