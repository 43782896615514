
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'
import { NavBarType, ProjectGetType } from '@/types/Enums'
import { UserSettings } from '@/types/Types'
import { Observer } from 'fast-json-patch/module/duplex'
import * as jsonpatch from 'fast-json-patch'
import LoadingCard from '@/components/LoadingCard.vue'
import { Operation } from 'fast-json-patch/module/core'
import { UserService } from '@/services/UserService'

const UserSettingsViewProps = Vue.extend({
  props: {
    //
  }
})

@Component({
  components: { LoadingCard }
})
export default class UserSettingsView extends UserSettingsViewProps {
  userSettingsObserver: Observer<UserSettings> | null = null
  userSettings: UserSettings | null = null
  loading = false

  get projectTypes () {
    return this.$store.state.projects.projectTypes
  }

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get isBasicUser () {
    return this.$store.getters.isBasicUser
  }

  submit () {
    if (this.form.validate()) {
      let patch: Operation[] | null = []

      if (this.userSettingsObserver) {
        patch = jsonpatch.generate(this.userSettingsObserver)
      }

      if (patch.length > 0) {
        this.loading = true

        UserService.patchUserSettings(patch).then(() => {
          this.$store.dispatch('notifications/showSuccess', {
            text: this.$t('notifications.userSettings.updateSuccess').toString()
          })
          this.getUserSettings().then(() => {
            this.$store.dispatch('projects/getProjects', { type: ProjectGetType.EXAMPLE_FILTERED, routeId: this.$route.params.id })
          })
        }).catch((error) => {
          this.$log.debug('Error pulling user settings: ', error)
          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.userSettings.updateError')
          })
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }

  getUserSettings () {
    return new Promise<void>((resolve, reject) => {
      this.$store.dispatch('pullUserSettings').then(() => {
        this.userSettings = JSON.parse(JSON.stringify(this.$store.state.auth.userSettings))

        if (this.userSettings) {
          this.userSettingsObserver = jsonpatch.observe(this.userSettings)
        }

        resolve()
      }).catch((error) => {
        this.$log.debug('Error pulling user settings: ', error)
        reject(error)
      })
    })
  }

  mounted () {
    this.$store.commit('projects/setNavBarType', NavBarType.Default)
    this.$store.dispatch('projects/getProjectTypes')

    this.getUserSettings()
  }
}
