<template>
  <v-app>
    <GlobalHeader v-if="isAuth"/>
    <ProjectNavigationBox v-if="isAuth" />
    <v-main :class="isAuth ? '' : 'login-bg'">
      <GlobalNotificationBar />
      <router-view :key="this.$route.fullPath"/>
    </v-main>
    <GlobalFooter />
  </v-app>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import GlobalNotificationBar from '@/components/GlobalNotificationBar.vue'
import ProjectNavigationBox from '@/components/ProjectNavigationBox'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'

@Component({
  components: {
    GlobalFooter,
    GlobalHeader,
    ProjectNavigationBox,
    GlobalNotificationBar
  }
})
export default class App extends Vue {
  get isAuth () {
    return this.$store.getters.isAuthenticated
  }
}
</script>

<style lang="scss">
@import 'src/scss/_vue.scss';
@import 'src/scss/_icons.scss';
</style>
