import { DateTime } from 'luxon'

export const MiscUtils = {
  getMailRegex () {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },

  getPasswordLengthRegex () {
    return /^(?=.*[A-Za-z])(?=.*\d).{8,}$/
  },

  getFormattedDateString (dateString: string, language: string) {
    if (dateString && !!DateTime.fromISO(dateString)) {
      return DateTime.fromISO(dateString).setLocale(language).toLocaleString()
    } else {
      return ''
    }
  },

  getLanguage () {
    return 'en'
  }
}
