
import Vue from 'vue'
import Component from 'vue-class-component'
import { mail, required } from '@/utils/InputRules'
import { VForm } from '@/$refs'
import { AuthService } from '@/services/AuthService'

const RequestPasswordResetProps = Vue.extend({
  props: {
    //
  }
})

@Component
export default class RequestPasswordReset extends RequestPasswordResetProps {
  passwordResetRequested = false
  email = ''
  emailRule = [required(), mail()]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  requestPasswordReset () {
    if (this.form.validate()) {
      AuthService.requestPasswordReset(this.email).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.passwordReset.resetRequest.success').toString()
        })

        this.passwordResetRequested = true
      }).catch((error) => {
        this.$log.debug('Password reset request failed', error)
        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.passwordReset.resetRequest.failed').toString()
        })
      })
    }
  }
}
