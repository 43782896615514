
import Component from 'vue-class-component'
import Vue from 'vue'
import { NavBarType } from '@/types/Enums'
import LoadingCard from '@/components/LoadingCard.vue'
import ProjectTable from '@/components/admin/ProjectTable.vue'

const ProjectManagementProps = Vue.extend({
  props: {
    //
  }
})
@Component({
  components: { ProjectTable, LoadingCard }
})
export default class ProjectManagement extends ProjectManagementProps {
  loading = false

  mounted (): void {
    this.$store.commit('projects/setNavBarType', NavBarType.Admin)
  }
}
