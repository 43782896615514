import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { UserLogin } from '@/types/Types'

export const AuthService = {
  updatePassword (oldPassword: string, newPassword: string): AxiosPromise {
    const params = new URLSearchParams()
    params.set('old', oldPassword)
    params.set('new', newPassword)

    return ApiService({
      url: '/auth/update/password',
      method: 'put',
      data: params
    })
  },

  getIsAdmin () {
    return ApiService({
      url: '/userIsAdmin',
      method: 'get'
    })
  },

  getCurrentUser () {
    return ApiService({
      url: '/currentUser',
      method: 'get'
    })
  },

  login (user: UserLogin) {
    return ApiService({
      url: '/auth/login',
      data: user,
      method: 'post'
    })
  },

  requestPasswordReset (email: string) {
    return ApiService({
      url: '/auth/requestPasswordReset',
      data: { email },
      method: 'post'
    })
  },

  resetPassword (token: string, password: string, email: string): AxiosPromise {
    const params = new URLSearchParams()
    params.set('email', email)
    params.set('password', password)

    return ApiService({
      url: '/auth/resetPassword/' + token,
      method: 'put',
      data: params
    })
  }
}
