import { PluginObject } from 'vue'
import VueLogger from 'vuejs-logger'
import { ILoggerOptions } from 'vuejs-logger/dist/interfaces/logger-options'
import { LogLevels } from 'vuejs-logger/dist/enum/log-levels'

const isProduction = process.env.NODE_ENV === 'production'

export const logOptions = {
  isEnabled: true,
  logLevel: isProduction ? LogLevels.ERROR : LogLevels.DEBUG,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

export const loggerPlugin = (VueLogger as unknown) as PluginObject<ILoggerOptions>
