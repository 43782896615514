import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { SortParams } from '@/types/Types'

export const ProjectTypeService = {
  getProjectByType (type: string, params: SortParams) {
    return ApiService({
      url: '/projects/user/' + type,
      method: 'get',
      params: params
    })
  },

  getProjectTypeById (projectTypeId: string): AxiosPromise {
    return ApiService({
      url: '/projectType/' + projectTypeId,
      method: 'get'
    })
  },

  getProjectTypes (): AxiosPromise {
    return ApiService({
      url: '/projectTypes',
      method: 'get'
    })
  }
}
