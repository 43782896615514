import { render, staticRenderFns } from "./ProjectAnalysis.vue?vue&type=template&id=1284099a&scoped=true&"
import script from "./ProjectAnalysis.vue?vue&type=script&lang=ts&"
export * from "./ProjectAnalysis.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1284099a",
  null
  
)

export default component.exports